import { Col, Container, Row } from "react-bootstrap";
import { CiLocationOn } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const sizeIcon = 40;

const ContactUs = () => {
	const { t } = useTranslation(); // Hook to access translation function

	return (
		<section id="contact-us" className="wow fadeInUpBig">
			<Container>
				<Container className="header-section">
					<h1>{t("contactUs.header")}</h1>
				</Container>
				<Container className="items">
					<Row>
						<Col className="col-lg-6 col-md-6 col-12">
							<div className="item">
								<CiLocationOn size={sizeIcon} />
								<h3>{t("contactUs.address")}</h3>
								<p>{t("contactUs.addressContent")}</p>
							</div>
						</Col>
						{/* <Col className="col-lg-4 col-md-4 col-12">
							<div className="item">
								<BsTelephone size={sizeIcon} />
								<h3>{t("contactUs.phone")}</h3>
								<a href="tel:+8455895548855">
									<p>{t("contactUs.phoneContent")}</p>
								</a>
							</div>
						</Col> */}
						<Col className="col-lg-6 col-md-6 col-12">
							<div className="item">
								<AiOutlineMail size={sizeIcon} />
								<h3>{t("contactUs.email")}</h3>
								<a href="mailto:contact@tomotech.com.vn">
									<p>{t("contactUs.emailContent")}</p>
								</a>
							</div>
						</Col>
					</Row>
				</Container>
				<iframe
					title="Share Location"
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d391.4465962427489!2d105.81197438392219!3d21.02913984429652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab664084d367%3A0xcee7281404f6104e!2zMTggxJAuIE5ndXnhu4VuIENow60gVGhhbmgsIE5n4buNYyBLaMOhbmgsIEJhIMSQw6xuaCwgSMOgIE7hu5lpLCBWaeG7h3QgTmFt!5e0!3m2!1svi!2s!4v1731381528713!5m2!1svi!2s"
					allowFullScreen
					aria-hidden="false"
					tabIndex="0"
				/>
			</Container>
		</section>
	);
};

export default ContactUs;
