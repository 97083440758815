import { Carousel, Container, Button } from "react-bootstrap";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const Banner = (dataJson) => {
	const { t } = useTranslation(); // Use translation hook

	const propLink = {
		spy: true,
		smooth: "easeInOutQuint",
		offset: -100,
		duration: 100,
	};

	return (
		<section id="banner" className="">
			<Container fluid>
				<Carousel
					className="carousel-banner"
					interval={4000}
					fade={true}
				>
					{dataJson.data.images.map((data) => {
						return (
							<Carousel.Item key={data.id}>
								<img
									className="d-block w-100"
									src={require(`${data.url}`)}
									alt={data.alt}
								/>
							</Carousel.Item>
						);
					})}
				</Carousel>
				<Container className="intro-banner" fluid>
					<Container className="intro-banner-content">
						<h1>
							{t("banner.makingYourIdeas")}{" "}
							<span>{t("banner.yourIdeas")}</span>
						</h1>
						<h1>{t("banner.happen")}</h1>
						<Link {...propLink} to="profile">
							<Button>{t("banner.getStarted")}</Button>
						</Link>
						<Link {...propLink} to="services">
							<Button>{t("banner.ourServices")}</Button>
						</Link>
					</Container>
				</Container>
			</Container>
		</section>
	);
};

export default Banner;
