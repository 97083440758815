import { useEffect, useState } from 'react';
import { Container, Nav,  Navbar, Modal } from 'react-bootstrap';
import { Link, animateScroll } from 'react-scroll';
import { PiCaretUpBold } from 'react-icons/pi';
import logo_origin from './assets/img/logo-tomotech/logo-full-origin.png';

const NavBar = () => {
    const [showModal, setShowModal] = useState(false);
    // const [dropdownOpen, setDropdownOpen] = useState(false); // State để quản lý dropdown

    const handleModalOpen = () => {
        setShowModal(true);
    }

    const handleModalClose = () => {
        setShowModal(false);
    }

    const handleOnScrollTop = () => {
        const defaultActiveKey = document.querySelector(".defaultActiveKey");
        const floatingIconHome = document.querySelector(".floating-icon-home");
        const navBar = document.getElementById("navbar");

        const scrollPosition = window.scrollY;

        if (scrollPosition > navBar.offsetHeight) {
            floatingIconHome.classList.add("floating-icon-home-visible");
            defaultActiveKey.classList.remove("nav-link", "activated");
        } else {
            defaultActiveKey.classList.add("nav-link", "active");
            floatingIconHome.classList.remove("floating-icon-home-visible");
        }
    }

    useEffect(() => {
        handleOnScrollTop();
        window.addEventListener('scroll', handleOnScrollTop);
        return () => {
            window.removeEventListener('scroll', handleOnScrollTop);
        }
    }, []);

    const propLink = {
        activeClass: "nav-link active",
        spy: true,
        smooth: 'easeInOutQuint',
        offset: -100,
        duration: 100,
    };

    return (
        <>
            <section id='navbar' className='wow fadeInLeft'>
                <Navbar className='navbar-contents' expand="lg" expanded={false}>
                    <Container>
                    <Navbar.Brand href='#top-bar'>
                    <img src={logo_origin} alt="Logo" style={{ height: '40px' }} />
                    </Navbar.Brand>

                        <Navbar.Toggle aria-controls='basic-navbar-nav' onClick={handleModalOpen}></Navbar.Toggle>
                        <Navbar.Collapse id='basic-navbar-nav'>
                            <Container className="navbar-detail">
                                <Nav defaultActiveKey="top-bar">
                                    <Link {...propLink} to='top-bar' className='defaultActiveKey nav-link active'>Home</Link>
                                    <Link {...propLink} to='profile'>About Us</Link>
                                    <Link {...propLink} to='services'>Services</Link>
                                    {/* <Link {...propLink} to='portfolio'>Portfolio</Link>
                                    <Link {...propLink} to='our-teams'>Team</Link> */}
                                      <Link {...propLink} to='career' onClick={handleModalClose}>Career</Link>

                                    {/* <NavDropdown
                                        title="Dropdown"
                                        id="basic-nav-dropdown"
                                        show={dropdownOpen} // Hiển thị dropdown dựa trên trạng thái
                                        onMouseEnter={() => setDropdownOpen(true)} // Mở dropdown khi hover
                                        onMouseLeave={() => setDropdownOpen(false)} // Đóng dropdown khi rời chuột
                                    >
                                        <NavDropdown.Item><Link {...propLink} to='action/3.1'>Drop Down 1</Link></NavDropdown.Item>
                                        <NavDropdown.Item><Link {...propLink} to='action/3.2'>Drop Down 2</Link></NavDropdown.Item>
                                        <NavDropdown.Item><Link {...propLink} to='action/3.3'>Drop Down 3</Link></NavDropdown.Item>
                                        <NavDropdown.Item><Link {...propLink} to='action/3.4'>Drop Down 4</Link></NavDropdown.Item>
                                        <NavDropdown.Item><Link {...propLink} to='action/3.5'>Drop Down 5</Link></NavDropdown.Item>
                                        <NavDropdown.Item><Link {...propLink} to='action/3.6'>Drop Down 6</Link></NavDropdown.Item>
                                    </NavDropdown> */}
                                    

                                    <Link {...propLink} to='contact-us'>Contact</Link>
                                </Nav>
                            </Container>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <Modal
                    show={showModal}
                    onHide={handleModalClose}
                    className="modal-navbar-dialog"
                >
                    <Modal.Header closeButton>
                        <Modal.Title><span className='first-span-modal'>Reve</span><span className='last-span-modal'>al</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Link {...propLink} to='banner' onClick={handleModalClose}>Home</Link>
                        <Link {...propLink} to='profile' onClick={handleModalClose}>About Us</Link>
                        <Link {...propLink} to='services' onClick={handleModalClose}>Services</Link>
                        {/* <Link {...propLink} to='portfolio' onClick={handleModalClose}>Portfolio</Link> */}
                        <Link {...propLink} to='career' onClick={handleModalClose}>Career</Link>
                        {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                            <NavDropdown.Item><Link {...propLink} to='action/3.1' onClick={handleModalClose}>Drop Down 1</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link {...propLink} to='action/3.2' onClick={handleModalClose}>Drop Down 2</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link {...propLink} to='action/3.3' onClick={handleModalClose}>Drop Down 3</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link {...propLink} to='action/3.4' onClick={handleModalClose}>Drop Down 4</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link {...propLink} to='action/3.5' onClick={handleModalClose}>Drop Down 5</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link {...propLink} to='action/3.6' onClick={handleModalClose}>Drop Down 6</Link></NavDropdown.Item>
                        </NavDropdown> */}
                        <Link {...propLink} to='contact-us' onClick={handleModalClose}>Contact</Link>
                    </Modal.Body>
                </Modal>
            </section>

            <div className='floating-icon-home'>
                <Link to="#" onClick={() => animateScroll.scrollToTop({ duration: 100 })}><PiCaretUpBold /></Link>
            </div>
        </>
    )
}

export default NavBar;