import { Col, Container, Row } from "react-bootstrap"

const Profile = () => {
    return (
        <section id="profile" className="wow fadeInUpBig" data-wow-duration="1s">
            <Container>
                <Row>
                    {/* <Col className="col-lg-6 col-md-12">
                        <Container>
                            <img src={imgProfile} alt="profile"></img>
                        </Container>
                    </Col> */}
                    <Col className="col-lg-12 col-md-24">
                        <Container id="profile-detail">
                            <h1>About Tomotech</h1>
                            <h3>Tomotech is a leading provider of comprehensive technology solutions, specializing in hardware and software outsourcing services that empower businesses to reach new heights of efficiency and innovation. With a focus on cost-effective solutions tailored to each client’s unique needs, Tomotech leverages its deep expertise and innovative approach to deliver top-quality technology solutions across industries. From hardware infrastructure to software development and integration, Tomotech’s dedicated team works collaboratively with clients to create scalable, efficient systems that drive success. Trust Tomotech to be your partner in technology transformation, bringing your vision to life through customized, high-performance solutions.

Tomotech’s team is composed of highly skilled professionals with deep expertise across both hardware and software domains. With a robust foundation in cutting-edge technology and years of industry experience, the team brings unparalleled technical insight and problem-solving abilities to every project. They are not only adept at designing and implementing tailored solutions but are also committed to continuous learning and innovation. This dedication ensures Tomotech remains agile, effective, and highly responsive to the evolving needs of clients, making the team a powerful asset in driving technological transformation and business success.
</h3>
                            {/* <ListGroup className="custom-list-group">
                                <ListGroupItem><IoMdCheckmarkCircleOutline size={25} /> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</ListGroupItem>
                                <ListGroupItem><IoMdCheckmarkCircleOutline size={25} /> Duis aute irure dolor in reprehenderit in voluptate velit.</ListGroupItem>
                                <ListGroupItem><IoMdCheckmarkCircleOutline size={25} /> Ullamco laboris nisi ut aliquip ex
                                    ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                                    trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.
                                </ListGroupItem>
                            </ListGroup> */}
                        </Container>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
export default Profile