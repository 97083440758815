import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next"; // Import useTranslation hook

const Profile = () => {
	const { t } = useTranslation(); // Use translation hook

	return (
		<section id="career" className="wow fadeInUpBig" data-wow-duration="1s">
			<Container>
				<Row>
					{/* <Col className="col-lg-6 col-md-12">
                        <Container>
                            <img src={imgProfile} alt="profile"></img>
                        </Container>
                    </Col> */}
					<Col className="col-lg-12 col-md-24">
						<Container id="career-detail">
							<h1>{t("profile.career")}</h1>
							<h3>
								{t("profile.description")}
								<br />
								{t("profile.contact")}{" "}
								<a href="/">
									<span>{t("profile.email")}</span>
								</a>{" "}
								{t("profile.joinTeam")}
							</h3>
							{/* <ListGroup className="custom-list-group">
                                <ListGroupItem><IoMdCheckmarkCircleOutline size={25} /> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</ListGroupItem>
                                <ListGroupItem><IoMdCheckmarkCircleOutline size={25} /> Duis aute irure dolor in reprehenderit in voluptate velit.</ListGroupItem>
                                <ListGroupItem><IoMdCheckmarkCircleOutline size={25} /> Ullamco laboris nisi ut aliquip ex
                                    ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                                    trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.
                                </ListGroupItem>
                            </ListGroup> */}
						</Container>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Profile;
