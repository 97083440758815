import { Col, Container, Row } from "react-bootstrap"
import { GiMechanicalArm } from "react-icons/gi";
import { IoHardwareChipOutline } from "react-icons/io5";
import { GiAutomaticSas } from "react-icons/gi";
import { GrCloudSoftware } from "react-icons/gr";

const sizeIcon = 90;
const iconMaps = {
    'GiMechanicalArm': GiMechanicalArm,
    'IoHardwareChipOutline': IoHardwareChipOutline,
    'GiAutomaticSas': GiAutomaticSas,
    'GrCloudSoftware': GrCloudSoftware
}


const Services = (dataJson) => {



    return (
        <section id="services">
            <Container>
                <Container className="header-section">
                    <h1>Services</h1>
                    <p>Tomotech offers an extensive range of technology services that cater to diverse business needs, ensuring each solution is customized to deliver maximum impact. Our mechanical design services focus on creating precise, efficient, and durable systems, tailored to specific requirements across various industries. In electronics hardware design, we specialize in developing innovative, high-performance hardware solutions that enhance functionality and reliability.
Our automation systems enable clients to streamline processes, boost efficiency, and reduce operational costs by implementing smart, adaptive automation tailored to each environment. Additionally, Tomotech’s embedded software expertise ensures that our clients’ hardware systems are powered by robust, scalable software that optimizes performance. By providing these services under one roof, Tomotech empowers businesses to achieve technological advancement and competitive advantage through integrated, high-quality solutions.
</p>
                </Container>

                <Container id="services-box">
                    <Row>
                        {
                            dataJson.data.services.map((data) => {
                                const IconComponent = iconMaps[data.icon];
                                return (
                                    <Col key={data.id} className={`col-lg-6 col-md-12 wow ${data.wowClass}`} data-wow-duration={`${data.wowClassDuration}`}>
                                        <Container className="services-box-detail" >
                                            <IconComponent size={sizeIcon} />
                                            <Container>
                                                <h2>{data.title}</h2>
                                                <p>{data.detail}</p>
                                            </Container>
                                        </Container>
                                    </Col>
                                )
                            })
                        }


                    </Row>
                </Container>
            </Container>
        </section>
    )
}
export default Services